import * as React from "react";
import Layout from "../components/Layout";
import SeoComponent from "../components/SeoComponent";

const NotFoundPage = () => (
  <Layout>
    <SeoComponent title="404: Not found" />
    <h1> Seite nicht gefunden</h1>
  </Layout>
);

export default NotFoundPage;
